<template>
<div class="app" id="app">
	<template v-if="isError && $store.strings && $store.strings.general_error">
		<Header class="app__header" />
		<div class="app__body">
			<div class="app__body error">
				<div uk-icon="icon: warning; ratio: 3"></div>
				<div class="uk-alert uk-alert-danger">
					{{ isError }}
				</div>
				<p>{{ $store.strings.general_error }}</p>
				<p>
					<a :href="'mailto:' + $store.strings.general_email">{{ $store.strings.general_email }}</a>
					<br />
					<a :href="'tel:' + $store.strings.general_phone">{{ $store.strings.general_phone }}</a>
				</p>
			</div>
		</div>
	</template>
	<template v-else-if="isError">
		<Header class="app__header" />
		<div class="app__body">
			<div class="error">
				<div uk-icon="icon: warning; ratio: 3"></div>
				<div class="uk-alert uk-alert-danger">
					{{ isError }}
				</div>
				<p>
					An error occurred: System not available<br>
					If the error persists, please contact support.
				</p>
			</div>
		</div>
	</template>
	<template v-else>
		<div v-if="!isError && isMobile" class="landscape">
			<div uk-icon="icon: phone; ratio: 2"></div>
			<p>{{ $store.strings.general_forcePortrait }}</p>
		</div>
		<Header class="app__header" />
		<div class="app__body">
			<router-view />
		</div>
	</template>
	<Offcanvas />
</div>
</template>
<script>
import Header from './components/Header.vue'
import Offcanvas from './components/Offcanvas.vue'
import fetch from './fetchWithTimeout'
import { isMobile } from 'mobile-device-detect'
export default {
	name: 'App',
	components: {
		Header,
		Offcanvas,
	},
	metaInfo() {
		return {
			title: this.strings ? this.strings.general_title : 'Loading…'
		}
	},
	data() {
		return {
			strings: this.$store.strings,
			isMobile: isMobile,
			isLoading: true,
			isError: false,
		}
	},
	async created() {
		await this.fetchData()
	},
	methods: {
		async fetchJSON(url) {
			try {
				const response = await fetch(this.$store.apiBase + url)
				if (response.ok===false)
					throw new Error(`Call to '${url}' failed.`, response)
				return await response.json()
			} catch (error) {
					throw new Error(`Call to '${url}' failed.`, error)
			}
		},
		async fetchData() {
			this.isLoading = true
			console.info('🔄 Loading data…')

			// check if cms is available
			try {
				const health = await fetch(this.$store.apiBase + '/_health', null, 1500)
				if (!health.ok) {
					console.error('👾 CMS Check failed', health)
					this.isLoading = false
					this.isError = health
					return
				}
			} catch (error) {
				console.error('👾 CMS Check unavailable', error)
				this.isLoading = false
				this.isError = error
				return
			}

			// lang
			const fallbackLang = process.env.VUE_APP_FALLBACK_LANG
			const availableLanguages = (await this.fetchJSON('/api/i18n/locales')) || [ { code: fallbackLang, isDefault: false }]
			const availableLocales = []

			// get locales
			let locales = {}
			for (let locale of availableLanguages) {
				locales[locale.code] = {
					code: locale.code,
					name: locale.name || locale.code,
					requestURL: '/api/localization?locale=' + locale.code + '&populate=deep',
					default: locale.isDefault,
					current: false,
					translated: false,
					strings: {},
				}
			}

			// get data
			try {
				const [
					strings,
					settings,
					shops,
				] = await Promise.all([
					Promise.all(Object.values(locales).map(locale => this.fetchJSON(locale.requestURL).catch((lang) => { lang; }))),
					this.fetchJSON('/api/configuration?populate=deep'),
					this.fetchJSON('/api/shops?populate=deep'),
				])
				// strings
				for (let s=0; s<strings.length; ++s) {
					const code = Object.keys(locales)[s]
					if (strings[s] && strings[s].data && strings[s].data.attributes && strings[s].data.attributes.translated==true) {
						availableLocales.push(code)
						locales[code].translated = true
						locales[code].strings = strings[s].data.attributes
						delete(strings[s].data.attributes.translated)
					} else {
						locales[code].translated = false
					}
				}
				this.$store.settings = settings.data.attributes,
				this.$store.shops = shops.data,

				console.info('👨🏼‍🔧 Settings', this.$store.settings)

				// preferred shop
				// to set:    https://< HOST >/#/?partner=< SLUG >
				// to clear:  https://< HOST >/#/?partner
				if (this.$route.query.partner !== undefined) {
					!this.$route.query.partner ?
						localStorage.removeItem('partner') :
						localStorage.setItem('partner', this.$route.query.partner)
					// remove query param
					const query = Object.assign({}, this.$route.query);
					delete query.partner;
					this.$router.replace({
						...this.$router.currentRoute,
						query,
					})
				}
				this.$store.settings.shop = this.$store.settings.overrideShop && this.$store.settings.overrideShop.data ? this.$store.settings.overrideShop.data.attributes.slug : localStorage.getItem('partner')
				console.info('🛒 Shop:', this.$store.settings.shop ? this.$store.settings.shop : 'All')
			} catch (error) {
					// api not responding
					this.isError = error
			}

			// user lang
			let lang = fallbackLang
			if (this.$route.query.lang !== undefined) {
				lang = availableLocales.includes(this.$route.query.lang) ? this.$route.query.lang : lang;
				!this.$route.query.lang ?
					localStorage.removeItem('lang') :
					localStorage.setItem('lang', this.$route.query.lang)
				// remove query param
				const query = Object.assign({}, this.$route.query);
				delete query.lang;
				this.$router.replace({
					...this.$router.currentRoute,
					query,
				})
			} else if (localStorage.getItem('lang') != null) { // user lang in localStorage
				lang = availableLocales.includes(localStorage.getItem('lang')) ? localStorage.getItem('lang') : lang;
			} else { // try preferred user lang
				let userLang = navigator.language || navigator.userLanguage;
				userLang = userLang.split('-')[0];
				lang = availableLocales.includes(userLang) ? userLang : lang;
			}
			console.info('🏳️‍🌈 Language: ' + lang)
			document.documentElement.lang = this.$store.lang = lang
			locales[lang].current = true
			this.$store.locales = Object.values(locales)
			this.$store.strings = this.strings = locales[lang].strings

			// check if Ceramic API is available
			try {
				const status = await fetch(this.$store.apiBase + '/api/status', null, 1500)
				if (!status.ok) {
					console.error('👾 API Check failed', status)
					this.isLoading = false
					this.isError = status.statusText
					return
				}
			} catch (error) {
				console.error('👾 API Check unavailable', error)
				this.isLoading = false
				this.isError = error
				return
			}

			this.isLoading = false
		},
	},
}
</script>
<style lang="scss">
.app {
	font-family: var(--global-font-family);
	font-weight: 300;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;

	* {
		box-sizing: border-box;
	}
}

.app__header {
	width: 100%;
	position: relative;
	z-index: 2;
}

.app__body {
	// do not change this. it's max height for safari
	// https://github.com/philipwalton/flexbugs/issues/197#issuecomment-283211783
	flex: 1 1 0;
	flex-grow: 1;
	max-height: 100%;
	min-height: 0;
	position: relative;
	overflow: hidden;

	.uk-alert {
		padding: 15px;
	}
}

.landscape, .error {
	display: flex;
	background: #fff;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 3;
	@extend .uk-padding;

	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
}
.landscape {
	display: none;
}
@media (orientation: landscape) {
	body:not(.keyboard-open) {
		.landscape {
			display: flex;
		}
	}
}
</style>
