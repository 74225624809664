<template>
	<ViewLayout className="home">
		<template v-slot:body>
			<MediaBox v-if="$store.strings.home_media" v-bind:content="$store.strings.home_media" />
			<h1>{{ $store.strings.home_title }}</h1>
			<p v-if="$store.strings.home_intro">{{ $store.strings.home_intro }}</p>
		</template>
		<template v-slot:footer>
			<router-link to="cistern">
				<button class="uk-button uk-button-primary uk-button-large blue-action-button" onclick="_paq.push(['trackEvent', 'welcome', 'next']);">
					{{ $store.strings.home_start }}
				</button>
			</router-link>
		</template>
	</ViewLayout>
</template>

<script>
// @ is an alias to /src
import ViewLayout from '@/components/ViewLayout.vue'
import MediaBox from '@/components/MediaBox.vue'

export default {
	name: 'Home',
	components: {
		ViewLayout,
		MediaBox,
	},
}
</script>
<style lang="scss" scoped>
.home {
	.mediabox {
		height: 30vh;
	}
	ul {
		list-style: none;
		padding-left: 0;
		li {
			padding: 2px 0;
			padding-left: -2px;

			a {
				padding: 2px;
			}
		}
	}
}
</style>
