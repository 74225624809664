<template>
<div>
	<div>
		<button class="uk-button uk-button-primary" type="button">
			<span uk-icon="world"></span> <span v-if="currentLocale">{{currentLocale.name}}</span>
		</button>
	</div>
	<div id="langSwitcher" uk-dropdown="mode: click; animation: uk-animation-scale-up; duration: 200;">
		<ul class="uk-nav uk-dropdown-nav">
			<li v-for="locale in availableLocales" :key="locale.code" :class="(currentLocale && currentLocale.code == locale.code) ? 'uk-active' : '' ">
				<a v-on:click="changeLocale(locale.code)">{{locale.name}}</a>
			</li>
		</ul>
	</div>
</div>
</template>

<script>
import UIkit from 'uikit'
const sort = (a, b) => {
	if (a.default)
		return -1
	if (b.default)
		return 1
	return a.name > b.name
}
export default {
	name: 'LanguageSwitcher',
	computed: {
		currentLocale: function() {
			return this.$store.locales.filter(locale => locale.current)[0]
		},
		availableLocales: function() {
			return this.$store.locales.filter(locale => locale.translated).sort(sort)
		},
	},
	methods: {
		changeLocale: function(code) {
			for (let locale of this.$store.locales) {
				locale.current = locale.code == code
				if (locale.current) {
					this.$store.strings = locale.strings
					document.documentElement.lang = this.$store.lang = locale.code
					localStorage.setItem('lang', locale.code)
				}
				UIkit.dropdown('#langSwitcher').hide(false)
			}
		},
	}
}
</script>

<style lang="scss" scoped>
[uk-dropdown] {
	border-radius: 3px;
	background-color: transparentize(darken($global-inverse-color, 5%), 0.02);
	box-shadow: inset 2px 2px 10px transparentize($global-emphasis-color, 0.8);
	padding: 15px 25px;

	ul {
		a {
			color: $global-emphasis-color;
			white-space: normal;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		a:hover, a:focus, a:active, .uk-active a {
			color: $global-link-color;
		}
		a::before {
			content: '⚐';
		}
		.uk-active a::before {
			content: '⚑';
		}
	}
}

.uk-button {
	.uk-icon {
		bottom: 3px;
		margin-left: -15px;
		margin-right: 15px;
	}
}
</style>
