import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import router from './router'

console.info('version: ' + process.env.VUE_APP_VERSION)
console.info('current environment: ' + process.env.NODE_ENV)
console.info('api base: ' + process.env.VUE_APP_API_BASE)

// https://simplygoodwork.com/blog/creating-a-global-store-in-vue
Vue.config.productionTip = false
const store = Vue.observable({
  apiBase: process.env.VUE_APP_API_BASE,
  lang: process.env.VUE_APP_FALLBACK_LANG,
  settings: {},
  strings: {},
  locales: [],
  shops: {},
  version: process.env.VUE_APP_VERSION,
  requestID: null,
  request: {
    options: {
      flip: false,
      tank: null,
      deviceCrop: false,
    },
    images: {
      front: null,
      side: null,
      top: null,
    },
  },
})
self.store = store

Vue.prototype.$store = store
Vue.use(VueToast)
Vue.use(VueRouter)
UIkit.use(Icons)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

// workaround to disable landscape mediaquery if keyboard is open
import live from 'live-event'
const textInputSelectors = [
  'input[type="text"]',
  'input[type="password"]',
  'input[type="email"]',
  'textarea',
]

textInputSelectors.forEach(selector => {
  live('focusin', selector, () => {
    document.body.classList.add('keyboard-open')
  })
  live('focusout', selector, () => {
    document.body.classList.remove('keyboard-open')
  })
})

if (
  process.env.VUE_APP_DEBUG_MATOMO ||
  process.env.NODE_ENV === 'development'
) {
  // https://developer.matomo.org/guides/tagmanager/debugging
  // ^ das geht nicht deshalb diese custom lösung. ACHTUNG DIE EVENTS werden nicht mehr an matomo gesendet
  const maxAttempts = 5
  let attempts = 0
  const initMatomoDebug = () => {
    if (window._paq) {
      // override matomo push and log args
      window._paq = {
        push: data => {
          console.info('🎯', data)
        }
      }
    } else {
      setTimeout(() => {
        if (attempts <= maxAttempts) initMatomoDebug();
      }, 500)
    }

    attempts++
  }

  initMatomoDebug()
}
