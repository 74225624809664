<template>
	<div id="offcanvas" uk-offcanvas="flip: false; overlay: true;">
		<div class="uk-offcanvas-bar">
			<button class="uk-offcanvas-close" type="button" uk-close></button>
			<h1>{{ $store.strings.offcanvas_title || $store.strings.general_title }}</h1>
			<button class="uk-button uk-button-primary" v-on:click="restart();" v-if="!isHome && $store.strings.offcanvas_restart">
				<span uk-icon="home"></span> {{ $store.strings.offcanvas_restart }}
			</button>
			<h2 v-if="$store.strings.offcanvas_chooseLang" class="uk-heading-divider">{{ $store.strings.offcanvas_chooseLang }}</h2>
			<LanguageSwitcher />
			<h2 v-if="$store.strings.offcanvas_legal" class="uk-heading-divider">{{ $store.strings.offcanvas_legal }}</h2>
			<ul>
				<li v-if="$store.strings.general_termsOfUseURL"><a :href="$store.strings.general_termsOfUseURL.url" target="_blank">{{ $store.strings.general_termsOfUse }}</a></li>
				<li v-if="$store.strings.general_privacyPolicyURL"><a :href="$store.strings.general_privacyPolicyURL.url" target="_blank">{{ $store.strings.general_privacyPolicy }}</a></li>
			</ul>
			<p id="copyright" class="uk-position-bottom">
				<span v-if="$store.version" class="uk-badge">v{{ $store.version }}</span>
				<span v-if="$store.strings.offcanvas_copyright">{{ $store.strings.offcanvas_copyright }}</span>
			</p>
		</div>
	</div>
</template>

<script>
import LanguageSwitcher from './LanguageSwitcher.vue'
import UIkit from 'uikit'
export default {
	name: 'Offcanvas',
	components: {
		LanguageSwitcher,
	},
	computed: {
		isHome: function() { return this.$route.path == '/' }
	},
	methods: {
		restart: function() {
			window._paq.push(['trackEvent', 'restart'])
			UIkit.offcanvas('#offcanvas').hide()
			setTimeout(() => this.$router.push('/'), 500)
		},
	},
}
</script>

<style lang="scss" scoped>
.uk-offcanvas-bar {
	background-color: $global-inverse-color;
	padding-top: 50px;
}

.uk-offcanvas-close {
	padding: 15px;
}

.uk-button {
	.uk-icon {
		bottom: 3px;
		margin-left: -15px;
		margin-right: 15px;
	}
}

ul {
	list-style: none;
	padding-left: 0;
	li {
		padding: 2px 0;
		padding-left: -2px;

		a {
			padding: 2px;
		}
	}
}

#copyright {
	font-size: 0.8rem;
	line-height: 1.5rem;
	padding: inherit;

	.uk-badge {
		margin: 0 0.5em;
		padding: 0 8px; 
		position: relative;
			top: -2px;
			
		&:first-child {
			margin-left: 0;
		}	
		&:last-child {
			margin-right: 0;
		}
	}
}
</style>