<template>
	<div class="header">
		<!-- inner div is important for safari -->
		<button uk-toggle="target: #offcanvas" uk-icon="menu" class="uk-icon-link" type="button"></button>
		<div>
			<img class="logo" src="../assets/logo.svg" @click="goHome" />
		</div>
	</div>
</template>

<script>
export default {
	name: "Header",
	props: {},
	methods: {
		goHome() {
			window._paq.push(["global", "logo_clicked"]);
			if (this.$router.currentRoute.path !== "/") this.$router.push("/");
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
	display: flex;
	justify-content: space-between;
	background: #fff;
	box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1);
	@extend .uk-padding;
	padding-top: 12px;
	padding-bottom: 12px;
}

.logo {
	width: 120px;
}
</style>
