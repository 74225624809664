import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { isMobileOnly } from 'mobile-device-detect'
Vue.use(VueRouter)

let routes = []

if (isMobileOnly || process.env.NODE_ENV === 'development') {
  // we are on a smartphone
  // https://github.com/duskload/mobile-device-detect
  routes = [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/howto',
      name: 'How to',
      component: () =>
        import(/* webpackChunkName: 'howto' */ '../views/HowTo.vue'),
    },
    {
      path: '/cistern',
      name: 'cistern',
      component: () =>
        import(/* webpackChunkName: 'cistern' */ '../views/Cistern.vue'),
    },
    {
      path: '/cam-front',
      name: 'Cam Front',
      component: () =>
        import(/* webpackChunkName: 'cam-front' */ '../views/CamFront.vue'),
    },
    {
      path: '/cam-front-check',
      name: 'Cam Front Check',
      component: () =>
        import(/* webpackChunkName: 'cam-front-check' */ '../views/CamFrontCheck.vue'),
    },
    {
      path: '/cam-side',
      name: 'Cam Side',
      component: () =>
        import(/* webpackChunkName: 'cam-side' */ '../views/CamSide.vue'),
    },
    {
      path: '/cam-side-check',
      name: 'Cam Side Check',
      component: () =>
        import(/* webpackChunkName: 'cam-side-check' */ '../views/CamSideCheck.vue'),
    },
    {
      path: '/cam-top',
      name: 'Cam Top',
      component: () =>
        import(/* webpackChunkName: 'cam-top' */ '../views/CamTop.vue'),
    },
    {
      path: '/cam-top-check',
      name: 'Cam Top Check',
      component: () =>
        import(/* webpackChunkName: 'cam-top-check' */ '../views/CamTopCheck.vue'),
    },
    {
      path: '/result',
      name: 'Result',
      component: () =>
        import(/* webpackChunkName: 'result' */ '../views/Result.vue'),
    },
    {
      path: '/result/:id',
      name: 'Result',
      component: () =>
        import(/* webpackChunkName: 'result' */ '../views/Result.vue'),
    },
    {
      path: '/seat',
      name: 'Seat',
      component: () =>
        import(/* webpackChunkName: 'seats' */ '../views/Seat.vue'),
    },
    {
      path: '/shop',
      name: 'Shop',
      component: () =>
        import(/* webpackChunkName: 'seats' */ '../views/Shop.vue'),
    },
    {
      path: '/sorry',
      name: 'Sorry',
      component: () =>
        import(/* webpackChunkName: 'sorry' */ '../views/Sorry.vue'),
    },
    {
      path: '/success',
      name: 'Success',
      component: () =>
        import(/* webpackChunkName: 'sorry' */ '../views/Success.vue'),
    },
    {
      path: '*',
      name: 'Home',
      component: Home,
    },
  ]
} else {
  // other devices
  routes = [
    {
      path: '/',
      name: 'NotMobile',
      component: () =>
        import(/* webpackChunkName: 'notmobile' */ '../views/NotMobile.vue'),
    },
    {
      path: '*',
      redirect: '/',
    },
  ]
}

const router = new VueRouter({
  routes
});

export default router
