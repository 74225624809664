<template>
<div class="mediabox">
	<img v-if="content.data.attributes.mime.startsWith('image/')" :src="$store.apiBase + content.data.attributes.url" :alt="content.data.attributes.alternativeText" />
	<video v-if="content.data.attributes.mime.startsWith('video/')" :src="$store.apiBase + content.data.attributes.url" playsinline autoplay muted loop></video>
	<slot></slot>
</div>
</template>

<script>
export default {
	name: 'MediaBox',
	props: {
		content: Object,
		ratio: {
			type: Number,
			default: 1,
		},
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.mediabox {
	background-size: cover;
	background-position: center;
	width: 100%;
	padding-bottom: 100%;
	position: relative;

	img,
	video {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		height: 100%;
		width: 100%;
		object-fit: contain;
		object-position: center;
	}
}
</style>
