<template>
<div class="view" :data-view="className" :class="hasFooterSlot ? 'view--hasFooter ' + className: className">
	<slot name="cam"></slot>
	<main class="view__body">
		<slot name="body"></slot>
	</main>
	<footer class="view__footer" v-if="hasFooterSlot">
		<slot name="footer"></slot>
	</footer>
</div>
</template>

<script>
export default {
	name: 'ViewLayout',
	props: {
		className: String
	},
	computed: {
		hasFooterSlot() {
			return !!this.$slots.footer
		}
	}
}
</script>
<style lang="scss" scoped>
.view {
		display: flex;

		height: 100%;
		flex-direction: column;
}

.view__body {
		@extend .uk-padding;

		min-height: 0;
		height: 100%;
		overflow-y: auto;
		
		.view--hasFooter & {
			padding-bottom: 60px;
		}
		
		h1,
		h2 {
			// font-size: 1.65rem;
			margin-bottom: 15px;
		}
		h1 + h2{
			margin-top: 30px;
		}
		h1{
			font-size: $global-font-size * 1.3 * 1.3;
			line-height: $global-font-size * 1.3 * 1.3* 1.3;
		}
		h2{
			font-size: $global-font-size;
			font-weight: 700;
		}
		h3{
			font-size: $global-font-size;
			font-weight: 700;
		}
		ul{
			padding-left: 15px;
		}

}

.view__footer {
		display: flex;
		flex-shrink: 0;
		align-items: center;
		flex-direction: column;
		@extend .uk-padding;
		padding-top: 15px;
		padding-bottom: 15px;
		position: relative;
		background: #fff;

		&:before {
				content: '';
				display: block;
				position: absolute;
				height: 60px;
				top: -60px;
				background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
				left: 0;
				right: 0;
				pointer-events: none;
		}

}</style>
